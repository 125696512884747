import { useState, useCallback } from "react";
import ApiCalling from "../../../Services/ApiCalling";
import debounce from "lodash/debounce";
import { toast } from "react-toastify";
import {
  validateInput,
  validateForm,
} from "../../../Services/ValidationService";
import { useNavigate } from "react-router-dom";

const SignUpService = () => {
  const [input, setInput] = useState({});
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const [bankData, setBankData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [passwords, setPasswords] = useState(null);
  const [userNames, setUserNames] = useState(null);
  const [userIds, setUserIds] = useState(null);
  const [areaData, setAreaData] = useState([]);
  const domainName=  process.env.REACT_APP_APIDOMAIN_NAME;

  const fetchAreaByPin = async (pincode) => {
    const reqObject = {
      IsExternal: true,
      appid: "ROUNDPAYAPPID13APR20191351",
      loginTypeID: "1",
      version: "2.6",
      pincode,
    };
    try {
      const res = await ApiCalling.request(
        "POST",
        "App/GetAreabyPincodeRequest",
        reqObject,
        null
      );
      if (res.statuscode === 1) {
        setAreaData(res.data);
      } else {
        setAreaData([]);
      }
    } catch (error) {
      console.error("Error fetching Area Data:", error);
    }
  };

  const debouncedFetchUserName = useCallback(
    debounce((userID) => {
      fetchUserName(userID);
    }, 1000),
    []
  );

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInput((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    const error = validateInput(name, value);
    setErrors((prevState) => ({
      ...prevState,
      [name]: error,
    }));

    if (name === "ReferalIDStr") {
      debouncedFetchUserName(value);
    }
  };

 
  const fetchUserName = async (userID) => {
    try {
      const res = await ApiCalling.request(
        "POST",
        `GetUserNameByID?userID=${userID}`,
        null,
        null
      );
      setInput((prevState) => ({
        ...prevState,
        Referralname: res.name || "",
      }));
      if (!res.name) {
        toast.error("Invalid Referral ID!", { toastId: "Referral" });
      }
    } catch (error) {
      console.error("Error fetching user name:", error);
    }
  };

  const fetchBankList = async () => {
    try {
      const res = await ApiCalling.request("GET", "/GetBank", null, null);
      setBankData(res || []);
    } catch (error) {
      console.error("Error fetching bank data:", error);
    }
  };

  const handleSubmitModal = () => {
    setShowModal(false);
    navigate("/login");
  };
 
  const handleSubmit = async () => {
    try {
      const bodyObject = {
        loginTypeID: 1,
        domain: domainName,
        appid: "ROUNDPAYAPPID13APR20191351",
        imei: "NA",
        version: "1.16",
        serialNo: "YLMBSWYTUGLBJNJV",
        regKey: "",
        userCreate: {
          ReferalIDStr: input?.ReferalIDStr,
          name: input?.name,
          outletName: input?.name,
          emailid: input?.emailid,
          mobileNo: input?.mobileNo,
          pincode: input?.pincode,
          areaId: input?.areaId,
          cityId: areaData?.[0]?.cityId || "",
          stateId: areaData[0].stateId || "",
          roleID: 3,
          Password: input?.Password,
          BankName: input?.BankName ||"",
          AccountHolder: input?.AccountHolder ||"",
          AccountNo: input?.AccountNo || "",
          UPIID: input?.UPIID ||"",
          IFSC: input?.IFSC ||"",
          EPIN: input?.EPIN,
          legs: "L",
        },
      };
      const formErrors = validateForm(bodyObject.userCreate);
      setErrors(formErrors);
      if (Object.keys(formErrors).length > 0) {
        return;
      }
      const res = await ApiCalling.request(
        "POST",
        "App/AppUserSignup",
        bodyObject,
        null
      );
      if (res?.statuscode === 1) {
        toast.success(res?.msg, { toastId: "AppSignup Success" });
        setPasswords(res?.password);
        setUserNames(res?.userName);
        setUserIds(res?.userId);
        setShowModal(true);
        setInput({
          ReferalIDStr: "",
          name: "",
          emailid: "",
          mobileNo: "",
          pincode: "",
          Password: "",
          BankName: "",
          AccountHolder: "",
          AccountNo: "",
          UPIID: "",
          IFSC: "",
          EPIN: "",
          stateId:"",
          cityId:"",
          areaId:""
        });
      } else {
        toast.error(res?.msg, { toastId: "AppSignup error" });
      }
    } catch (error) {
      console.error("Error during registration:", error);
      //  toast.error('An error occurred during login. Please try again.');
    }
  };

  return {
    input,
    handleChange,
    handleSubmit,
    errors,
    fetchBankList,
    bankData,
    showModal,
    handleSubmitModal,
    passwords,
    userIds,
    userNames,
    areaData,
    fetchAreaByPin,
    setAreaData,
    
  };
};
export default SignUpService;
