export const validateInput = (name, value) => {
    let error = "";

    switch (name) {
        case "ReferalIDStr":
            if (!value) {
                error = "Referral ID is required";
            }
            break;
        case "name":
            if (!value) {
                error = "Name is required";
            }
            break;
        case "emailid":
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!value) {
                error = "Email is required";
            } else if (!emailRegex.test(value)) {
                error = "Invalid email format";
            }
            break;
        case "mobileNo":
            const mobileRegex = /^[0-9]{10}$/;
            if (!value) {
                error = "Mobile number is required";
            } else if (!mobileRegex.test(value)) {
                error = "Invalid mobile number format";
            }
            break;
        case "pincode":
            if (!value) {
                error = "Pincode is required";
            }
            break;
        case "Password":
            if (!value) {
                error = "Password is required";
            }
            break;
        // case "BankName":
        //     if (!value) {
        //         error = "Bank name is required";
        //     }
        //     break;
        // case "AccountHolder":
        //     if (!value) {
        //         error = "Account holder name is required";
        //     }
        //     break;
        // case "AccountNo":
        //     if (!value) {
        //         error = "Account number is required";
        //     }
        //     break;
        // case "IFSC":
        //     if (!value) {
        //         error = "IFSC code is required";
        //     }
        //     break;
        // case "UPIID":
        //     if (!value) {
        //         error = "UPI ID is required";
        //     }
        //     break;
        case "EPIN":
            if (!value) {
                error = "E-PIN is required";
            }
            break;
        default:
            break;
    }

    return error;
};

export const validateForm = (input) => {
    const errors = {};
    Object.keys(input).forEach((name) => {
        const error = validateInput(name, input[name]);
        if (error) {
            errors[name] = error;
        }
    });
    return errors;
};
