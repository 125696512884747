import React from "react";
import { Link, useNavigate } from "react-router-dom";
// import { useTheme } from "../../../utils/ThemeContext.js";

const Header = () => {

  const navigate = useNavigate();
  const apibaseURL = process.env.REACT_APP_APIBASE_URL;
  const date = new Date().getTime();
  // const { theme, toggleTheme } = useTheme();

  const goBack = () => {
    navigate(-1);
  };
  return (
    <>
      <div class="header header-fixed header-logo-center">
        <Link to={"/dashboard"} class="header-title">
          <img
            src={`${apibaseURL}Image/Website/1/logo.png?${date}}`}
            alt="Logo"
            className="logo-img"
            style={{ width: "150px" }}
          />
        </Link>
        <a
          onClick={goBack}
          navigate
          data-back-button=""
          class="header-icon header-icon-1 cursor-pointer"
        >
          <i class="fas fa-arrow-left"></i>
        </a>
        {/* <button
        onClick={toggleTheme} 
        className="header-icon header-icon-2 cursor-pointer"
      >
        {theme === 'light' ? (
          <i className="fas fa-moon"></i>
        ) : (
          <i className="fas fa-sun"></i>
        )}
      </button> */}
      </div>
</>
  );
};

export default Header;
